import { GrowthBook } from '@growthbook/growthbook-react';

const isSSR = typeof window === 'undefined';
const FEATURES_ENDPOINT = process.env.GATSBY_GROWTHBOOK_FEATURES_ENDPOINT;

export const growthBook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    console.log({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

if (!isSSR) {
  if (FEATURES_ENDPOINT) {
    // Load feature definitions from API
    fetch(FEATURES_ENDPOINT)
      .then((res) => res.json())
      .then((json) => {
        growthBook.setFeatures(json.features);
      })
      .catch(() => {
        // TODO send info to sentry
        console.log('Failed to fetch feature definitions from GrowthBook');
      });
  } else {
    throw new Error('GrowthBook disabled');
  }
}
