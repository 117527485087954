/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { ApolloProvider } from '@apollo/client';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import type { GatsbyBrowser } from 'gatsby';
// add polyfills that are required by some browsers (see INKWELL-WEB-1E)
import smartlookClient from 'smartlook-client';
import 'url-search-params-polyfill';
import { client } from './graphql-client';
import { growthBook } from './growth-book';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  if (smartlookClient.initialized()) {
    smartlookClient.record({
      forms: true,
      emails: true,
      ips: true,
      numbers: true,
    });
  }

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </GrowthBookProvider>
  );
};
