import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { navigate } from 'gatsby';

const uri = process.env.GATSBY_API_URI;

const link = from([
  onError(({ graphQLErrors }) => {
    if (
      graphQLErrors &&
      graphQLErrors.find(
        (e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED',
      ) &&
      typeof window !== 'undefined'
    ) {
      client.resetStore();

      const { pathname, search, hash } = window.location;

      navigate(`/login?t=${encodeURIComponent(pathname + search + hash)}`);
    }
  }),
  createUploadLink({
    uri,
    credentials: 'include',
  }),
]);

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Client: {
        fields: {
          applicants: relayStylePagination(),
        },
      },
    },
  }),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      // more information on this:
      // https://www.apollographql.com/docs/react/data/queries/#options
      // and the reason why it was introduced:
      // https://github.com/apollographql/apollo-client/pull/6712
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  },
});
